import { GETCONFIG, POSTCONFIG } from "../axios"
import CryptoJS from 'crypto-js';
// import Swal from 'sweetalert2'

export const getProfile = async () => {
    const response = await GETCONFIG("profile", {
        headers: {
            "Authorization": JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('authorization'), process.env.VUE_APP_BEARER_TOKEN_ENCRYPT_KEY).toString(CryptoJS.enc.Utf8))
        }
    })
    
    return {response}
}

export const updateProfile = async (data) => {
    const response = await POSTCONFIG("profile", data, {
        headers: {
            "Authorization": JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('authorization'), process.env.VUE_APP_BEARER_TOKEN_ENCRYPT_KEY).toString(CryptoJS.enc.Utf8))
        }
    })
    
    return {response}
}