// import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { currencyFormat } from '@/utils/helper'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export const useBarChart = defineStore('bar-chart', {
    state: () => ({
        // the main store to keep the unedited data 
        chartInfo: {
            name: [ 
                'January', 
                'February', 
                'March', 
                'April', 
                'May', 
                'June', 
                'July',
                'August', 
                'September', 
                'October', 
                'November', 
                'December'
            ],
            rate: [
                0, 
                0, 
                0, 
                0, 
                0, 
                0, 
                0,
                0, 
                0, 
                0, 
                0, 
                0
            ]
        },
        
        // released to the user
        updatedChartInfo: {
            name: [ 
                'January', 
                'February', 
                'March', 
                'April', 
                'May', 
                'June', 
                'July',
                'August', 
                'September', 
                'October', 
                'November', 
                'December'
            ],
            rate: [ 
                0, 
                0, 
                0, 
                0, 
                0, 
                0, 
                0,
                0, 
                0, 
                0, 
                0, 
                0
            ]
        }
    }),
    getters: {
        chartData() {
            return {
                labels: this.updatedChartInfo.name,
                datasets: [ 
                    { 
                        borderWidth: 2,
                        borderColor: "rgba(15, 113, 115)",
                        hoverBorderColor: "rgba(15, 113, 115,1)",
                        backgroundColor: "rgba(15, 113, 115,0.2)",
                        hoverBackgroundColor: "rgba(15, 113, 115,0.4)",
                        data: this.updatedChartInfo.rate,
                    } 
                ],
            }
        },
        chartOptions(){
            return {
                responsive: true,
                maintainAspectRatio:false,
                plugins: {
                    legend: {
                      display: false
                    },
                    tooltip: {
                        callbacks: {
                          label(context) {
                            return `₦ ${context.formattedValue}`;
                          },
                        },
                    },
                },
                scales: {
                    y: {
                        ticks: {
                            stepSize: 50,
                            reverse: false,
                            beginAtZero: true,
                            // suggestedMin: 500,
                            callback: (value) => {
                                return `₦ ${currencyFormat(value)}`;
                            },
                        },
                    }
                }
            }
        }
    },
    actions: {
        initChart() {
            this.chartInfo.name = [ 
                'January', 
                'February', 
                'March', 
                'April', 
                'May', 
                'June', 
                'July',
                'August', 
                'September', 
                'October', 
                'November', 
                'December'
            ],
            this.chartInfo.rate = [ 
                1000, 
                3000, 
                0, 
                4000, 
                1000, 
                5000, 
                5000,
                3000, 
                1000, 
                4000, 
                2500, 
                10000
            ]

            this.updatedChartInfo = this.chartInfo
        },
        updateChart(chart) {
            let arr = this.chartInfo.rate
            for (let key in chart) {
                const pocketId = this.chartInfo.name.indexOf(key)
                arr[pocketId] = chart[key]
            }
            this.chartInfo.rate = arr
            return this.updatedChartInfo = this.chartInfo                       
        },
    }
})
