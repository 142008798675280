import { GETCONFIG } from "../axios"
import CryptoJS from 'crypto-js';
// import Swal from 'sweetalert2'

export const getDashboard = async () => {
    const response = await GETCONFIG("dashboard", {
        headers: {
            "Authorization": JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('authorization'), process.env.VUE_APP_BEARER_TOKEN_ENCRYPT_KEY).toString(CryptoJS.enc.Utf8))
        }
    })
    
    return {response}
}