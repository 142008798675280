import { defineStore } from "pinia";
import { useRouter } from "vue-router";
import CryptoJS from 'crypto-js';
import { getProfile, updateProfile } from "../auth/user";

export const useProfile = defineStore("profile", {
    state: () => ({
        originalProfile: {},
        copiedProfile: {},
        loading: true,

        submitted: {},
        buttonLoader: false,

        displayProfile: {},

        // this is the data submitted by the user
        data: {email: "", password: ""},

        router: useRouter(),
    }),
    getters: {
        getAuth() {
            this.auth
        }
    },
    actions: {
        async fetchProfile() {
            this.originalProfile = await getProfile();
            this.copiedProfile = this.originalProfile.response.data.data.data
        },
        async setProfile() {
            if(localStorage.getItem('user')){
                this.displayProfile = await JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('user'), process.env.VUE_APP_BEARER_TOKEN_ENCRYPT_KEY).toString(CryptoJS.enc.Utf8));
                // console.log(this.displayProfile)
            }else{
                this.originalProfile = await getProfile();
                this.displayProfile = this.originalProfile.response.data.data.data
            }
        },
        async updateProfile() {
            this.buttonLoader = true
            this.submitted = this.copiedProfile

            if(!this.submitted.password){
                delete this.submitted['password']
                delete this.submitted['confirmPassword']
            }

            await updateProfile(this.submitted);
            this.buttonLoader = false;
        },
        logout() {
            localStorage.clear();
            this.router.push({name: 'login'})
        }
    }
});