const formatPrice = (value) => {
  let val = (value/1).toFixed(2).replace(',', '.')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
};

const formatNumber = (value) => {
  let val = (value/1).toFixed(2).replace('.', ',')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
};

const currencyFormat = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const dateFormat = (date) => {
  const currentDate = new Date(date);
  
  const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  };

  return currentDate.toLocaleDateString('en-US', options);
}


export { formatPrice, formatNumber, currencyFormat, dateFormat };
