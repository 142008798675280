import { defineStore } from "pinia";
import { useRouter } from "vue-router";
import { getDashboard } from "../controllers/dashboard";

export const useDashboard = defineStore("dashboard", {
    state: () => ({
        original: {},
        copied: {},
        loading: true,

        router: useRouter(),
    }),
    getters: {
        getAuth() {
            this.auth
        }
    },
    actions: {
        async fetchDashboard() {
            this.original = await getDashboard();
            if(this.copied !== this.original.response.data.data.data){
                this.copied = this.original.response.data.data.data
                return this.copied
            }
            return this.copied
        },
    }
});