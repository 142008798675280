/***************************
 * MAKE LIFE EASIER MY GUY *
 ***************************/

// all axios format for you
import axios from "axios"
// import { inject } from "vue"
import Swal from 'sweetalert2'

const baseURL = process.env.VUE_APP_BASE_URL;

// default config
axios.defaults.baseURL = baseURL;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['content-type'] = 'application/json';

export const GETCONFIG = async (url, config) => {
    let response = {};
    try {
        response = await axios.get(url, config);
        return {data: response, isFetched: true, state: 'success'}
    } catch (error) {
        if(error.response){
            new Swal({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message,
            })
        }else{
            new Swal({
                icon: 'error',
                title: 'Oops...',
                text: error.message,
            })
        }
        return {error: error, isFetched: true, state: 'error'}
    }
}

export const POST = async (url, data) => {
    let response = {};
    try {
        response = await axios.post(url, data);
        new Swal({
            icon: 'success',
            title: 'Action Completed Successfully.',
            // text: error.response.data.message,
        })
        return {data: response, isFetched: true, state: 'success'}
    } catch (error) {
        if(error.response){
            new Swal({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message,
            })
        }else{
            new Swal({
                icon: 'error',
                title: 'Oops...',
                text: error.message,
            })
        }
        return {error: error, isFetched: true, state: 'error'}
    }
}

export const POSTCONFIG = async (url, data, config) => {
    let response = {};
    try {
        response = await axios.post(url, data, config);
        new Swal({
            icon: 'success',
            title: 'Action Completed Successfully.',
            // text: error.response.data.message,
        })
        return {data: response, isFetched: true, state: 'success'}
    } catch (error) {
        if(error.response){
            new Swal({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message,
            })
        }else{
            new Swal({
                icon: 'error',
                title: 'Oops...',
                text: error.message,
            })
        }
        return {error: error, isFetched: true, state: 'error'}
    }
}

export const DELETECONFIG = async (url, config) => {
    let response = {};
    try {
        response = await axios.delete(url, config);
        return {data: response, isFetched: true, state: 'success'}
    } catch (error) {
        if(error.response){
            new Swal({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message,
            })
        }else{
            new Swal({
                icon: 'error',
                title: 'Oops...',
                text: error.message,
            })
        }
        return {error: error, isFetched: true, state: 'error'}
    }
}