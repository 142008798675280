import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

/* eslint-disable */ 
const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      auth: true
    },
    component: HomeView
  },
  {
    path: '/companies',
    name: 'companies',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/CompaniesView.vue')
  },
  {
    path: '/companies/:id',
    name: 'companies-id',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/CompanydetailView.vue')
  },
  {
    path: '/companies/:id/contact',
    name: 'companies-contact',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/ViewCompanyContactInformation.vue')
  },
  {
    path: '/companies/:id/invites',
    name: 'companies-invites',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/viewCompanyInvites.vue')
  },
  {
    path: '/companies/:id/payments',
    name: 'companies-payments',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/ViewCompanyEmployeePaymentHistory.vue')
  },
  {
    path: '/companies/:id/transactions',
    name: 'companies-transactions',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/viewCompanyTransactions.vue')
  },
  {
    path: '/companies/:id/payroll',
    name: 'company-payroll',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/ViewCompanyPayrollHistory.vue')
  },
  {
    path: '/companies/:id/employee',
    name: 'company-employee',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/ViewCompanyEmployee.vue')
  },
  {
    path: '/companies/:id/employee/profile',
    name: 'company-employee-profile',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/ViewCompanyEmployeeProfile.vue')
  },
  {
    path: '/companies/:id/employee/salary',
    name: 'company-employee-salary',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/ViewCompanyEmployeeSalaryBreakdown.vue')
  },
  {
    path: '/companies/:id/employee/payment',
    name: 'company-employee-payment',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/ViewCompanyEmployeePaymentHistory.vue')
  },
  {
    path: '/companies/:id/setting',
    name: 'company-setting',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/ViewSettingCompany.vue')
  },
  {
    path: '/setting',
    name: 'setting',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/GlobalSettings.vue')
  },
  {
    path: '/employee',
    name: 'employee',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/ViewEmployees.vue')
  },
  {
    path: '/employee/department',
    name: 'employee-department',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/ViewEmployeeDepartment.vue')
  },
  {
    path: '/employee/department/:id',
    name: 'employee-department-details',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/ViewEmployeeManageDepartment.vue')
  },
  {
    path: '/employee/department/:id/employee',
    name: 'employee-department-details-employee',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/ViewEmployeeManageEmployee.vue')
  },
  {
    path: '/employee/department/:id/settings',
    name: 'employee-department-details-setting',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/ViewEmployeeDepartmentSetting.vue')
  },
  {
    path: '/payroll',
    name: 'payroll',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/PayrollView.vue')
  },
  {
    path: '/payroll/:id',
    name: 'payroll-detail',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/PayrolldetailView.vue')
  },
  {
    path: '/manage-user',
    name: 'manage-user',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/ViewManageUser.vue')
  },
  {
    path: '/health-insurance',
    name: 'health-insurance',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/ViewHealthInsurance.vue')
  },
  ,
  {
    path: '/pension',
    name: 'pension',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/PensionView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      auth: true
    },
    component: () => import(/* webpackChunkName: "payroll" */ '../views/ProfileView.vue')
  },
  // {
  //   path: '/Taxnfh',
  //   name: 'Taxnfh',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "payroll" */ '../views/TaxView.vue')
  // },
  {
    path: '/signup',
    name: 'signup',
    meta: {
      auth: false
    },
    component: () => import(/* webpackChunkName: "signup" */ '../views/auth/SignupView.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      auth: false
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/LoginView.vue')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta: {
      auth: false
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/ResetView.vue')
  },
  {
    path: '/reset-password/verify',
    name: 'reset',
    meta: {
      auth: false
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/EmailverificationView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
})

router.beforeEach((to, from) => {
  const guard = localStorage.getItem('authorization') ? true : false

  if(guard === false && to.meta.auth !== false){
    return {name: 'login'}
  }else if(guard === true && to.meta.auth !== true){
    return {name: 'home'}
  }
})

export default router
