<template>
    <div class="flex">
        <SideNav />
        <MobileNav />
        <div class="content grow bg-[#F0F2F2] lg:ml-[260px] ml-[0] pb-[100px]">
            <HeroCard color="#0960CD">
                <header class=" absolute w-full max-w-[1138px] pl-[24px] pr-[18px]">
                    <div class="h-[64px]">
                        <!-- <a href="#" class="float-left md:block hidden leading-[64px] text-[14px] text-white">Compared to N0.0 last month</a> -->
                        <div class="action float-right mt-[11px]">
                            <div class="hero--info_user-account flex">
                                <!-- <div class="home--user-notifications relative md:w-[42px] w-[32px] md:h-[42px] h-[32px] bg-[#3287DD80] flex items-center justify-center rounded-[42px]">
                                    <div class="">
                                        <img src="@/assets/icons/Question.svg" alt="help icon">
                                    </div>
                                </div> -->
                                <!-- <div class="home--user-notifications relative lg:w-[196px] w-auto md:h-[42px] h-[35px] bg-[#3287DD80] py-[11px] px-[10px] rounded-[22px] md:ml-[24px] ml-[5px]">
                                    <div class="flex relative" @click="notification_dropdown = !notification_dropdown">
                                        <img src="@/assets/icons/BellRinging.svg" alt="help icon">
                                        <span class="text-white text-[14px] ml-[10px] mr-[12px] hidden lg:block">Your notifications</span>
                                        <div class="md:static absolute md:w-[24px] md:h-[24px] md:leading-[24px] w-[14px] h-[14px] leading-[14px] top-[-10px] right-[-10px] text-center rounded-[24px] bg-[#ff293e] text-[#ffffff] text-[.7em]"><span>99+</span></div>
                                    </div>
                                    <div :class="{hidden : !notification_dropdown}" class="notification-dropdown absolute md:right-[-55px] right-[-35px] top-[50px] md:bottom-[-395px] bottom-[-460px] md:w-[481px] w-screen md:h-[376px] h-[480px] p-[24px] rounded-[8px] bg-white shadow-2xl shadow-[#00000040] z-10 overflow-auto">
                                        <a href="#" class="block py-[16px] border-b-2 border-[#62666929]">
                                            <p class="text-[14px] font-medium">Title of Notification</p>
                                            <p class="mt-[4px] text-[13px] text-[#222222AD] font-medium">Description of the notification in full, with no truncation. This should be used for platform update or things that arent really long. Maybe we’d discuss what this feature is really needed</p>
                                        </a>
                                        <a href="#" class="block py-[16px] border-b-2 border-[#62666929]">
                                            <p class="text-[14px] font-medium">Title of Notification</p>
                                            <p class="mt-[4px] text-[13px] text-[#222222AD] font-medium">Description of the notification in full, with no truncation. This should be used for platform update or things that arent really long. Maybe we’d discuss what this feature is really needed</p>
                                        </a>
                                        <a href="#" class="block py-[16px] border-b-2 border-[#62666929]">
                                            <p class="text-[14px] font-medium">Title of Notification</p>
                                            <p class="mt-[4px] text-[13px] text-[#222222AD] font-medium">Description of the notification in full, with no truncation. This should be used for platform update or things that arent really long. Maybe we’d discuss what this feature is really needed</p>
                                        </a>
                                    </div>
                                </div> -->
                                <div class="home--img_user-account md:block hidden relative ml-[16px]">
                                    <div>
                                        <div class="w-[40px] h-[40px] flex items-center justify-center bg-white rounded-[64px]">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.4375 12.5C11.0263 12.5 13.125 10.4013 13.125 7.8125C13.125 5.22367 11.0263 3.125 8.4375 3.125C5.84867 3.125 3.75 5.22367 3.75 7.8125C3.75 10.4013 5.84867 12.5 8.4375 12.5Z" stroke="#626669" stroke-width="1.3" stroke-miterlimit="10"/>
                                            <path d="M1.73438 15.6249C2.55567 14.6461 3.58138 13.8589 4.73942 13.3189C5.89746 12.7789 7.15973 12.499 8.4375 12.499C9.71527 12.499 10.9775 12.7789 12.1356 13.3189C13.2936 13.8589 14.3193 14.6461 15.1406 15.6249" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M17.1875 11.875C17.8779 11.875 18.4375 11.3154 18.4375 10.625C18.4375 9.93464 17.8779 9.375 17.1875 9.375C16.4971 9.375 15.9375 9.93464 15.9375 10.625C15.9375 11.3154 16.4971 11.875 17.1875 11.875Z" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M17.1875 9.375V8.4375" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M16.1016 10L15.2969 9.53125" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M16.1016 11.25L15.2969 11.7188" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M17.1875 11.875V12.8125" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M18.2734 11.25L19.0781 11.7188" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M18.2734 10L19.0781 9.53125" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <div class="home--status_user-account border-2 border-white w-[12px] h-[12px] rounded-full bg-[#56CA00] absolute right-0 bottom-0"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div class="hero--greeting_user-account text-center pt-[85px]">
                    <span class="text-[32px] font-bold text-white">{{ `${timeOfDay()} ${profile.displayProfile.data ? profile.displayProfile.data.lastname : ''}` }}</span>
                    <!-- <div class="search relative w-[287px] mx-[auto] mt-[7px]">
                        <input type="text" class="w-[287px] h-[40px] text-[14px] text-white bg-[#3287DD] rounded-[100px] pl-[73px] pr-[37px]" placeholder="Search employee, actions etc.">
                        <img src="@/assets/icons/MagnifyingGlass.svg" class="absolute top-[50%] left-[38px] translate-y-[-50%]" alt="">
                    </div> -->
                </div>
            </HeroCard>
            <div class="briefing pl-[24px] pr-[18px] lg:h-[163px] h-auto">
                <div class="wrap grid lg:grid-cols-3 grid-cols-1 gap-4 w-[100%] max-w-[1138px] lg:h-[215px] h-auto rounded-[8px] lg:translate-y-[-68px] translate-y-[-38px]">
                    <div class="box h-auto lg:pb-[0] pb-[16px] bg-white rounded-[8px]">
                        <div class="info info2 pl-[31px] pt-[21px] h-full flex">
                            <div class="text grow relative">
                                <div>
                                    <p class="font-medium text-black text-[16px]">Companies</p>
                                    <p class="font-[450] text-[#000000AD] text-[14px]">Total number of companies</p>
                                </div>
                                <div class="lg:mb-[0] mb-[24px] mt-[10px]">
                                    <p class="font-bold text-[24px] leading-[36px] text-[#222222DE]">{{ dashboard.copied.companies }}</p>
                                </div>
                                <!-- <router-link to="/companies" class="lg:text-[14px] table text-[14px] lg:absolute bottom-[16px] font-medium rounded-[200px] text-[#0960CD] bg-[#F0F2F2] h-[41px] leading-[41px] items-center">
                                    <span class="px-[17px] pr-0 float-left">Manage Companies</span>
                                    <svg class="ml-[10px] translate-y-[10.5px] float-right mr-[15px]" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.125 10.5H16.875" stroke="#0960CD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M11.25 4.875L16.875 10.5L11.25 16.125" stroke="#0960CD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </router-link> -->
                            </div>
                            <div class="icon w-[48px] h-[48px] mr-[24px] flex items-center justify-center rounded-full bg-[#F0F2F2]">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.375 18.5625H20.625" stroke="#626669"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.375 18.5625V3.4375C12.375 3.25516 12.3026 3.0803 12.1736 2.95136C12.0447 2.82243 11.8698 2.75 11.6875 2.75H3.4375C3.25516 2.75 3.0803 2.82243 2.95136 2.95136C2.82243 3.0803 2.75 3.25516 2.75 3.4375V18.5625" stroke="#626669"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M19.25 18.5625V8.9375C19.25 8.75516 19.1776 8.5803 19.0486 8.45136C18.9197 8.32243 18.7448 8.25 18.5625 8.25H12.375" stroke="#626669"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M5.5 6.1875H8.25" stroke="#626669"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M6.875 11.6875H9.625" stroke="#626669"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M5.5 15.125H8.25" stroke="#626669"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M15.125 15.125H16.5" stroke="#626669"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M15.125 11.6875H16.5" stroke="#626669"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="box h-auto lg:pb-[0] pb-[16px] bg-white rounded-[8px]">
                        <div class="info info2 pl-[31px] pt-[21px] h-full flex">
                            <div class="text grow relative">
                                <div>
                                    <p class="font-medium text-black text-[16px]">Employees</p>
                                    <p class="font-[450] text-[#000000AD] text-[14px]">Total number of employees</p>
                                </div>
                                <div class="lg:mb-[0] mb-[24px] mt-[10px]">
                                    <p class="font-bold text-[24px] leading-[36px] text-[#222222DE]">{{ dashboard.copied.employees }}</p>
                                </div>
                                <!-- <router-link to="/employee" class="lg:text-[14px] table text-[14px] lg:absolute bottom-[16px] font-medium rounded-[200px] text-[#0960CD] bg-[#F0F2F2] h-[41px] leading-[41px] items-center">
                                    <span class="px-[17px] pr-0 float-left">Manage Employees</span>
                                    <svg class="ml-[10px] translate-y-[10.5px] float-right mr-[15px]" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.125 10.5H16.875" stroke="#0960CD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M11.25 4.875L16.875 10.5L11.25 16.125" stroke="#0960CD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </router-link> -->
                            </div>
                            <div class="icon w-[48px] h-[48px] mr-[24px] flex items-center justify-center rounded-full bg-[#F0F2F2]">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.4375 12.5C11.0263 12.5 13.125 10.4013 13.125 7.8125C13.125 5.22367 11.0263 3.125 8.4375 3.125C5.84867 3.125 3.75 5.22367 3.75 7.8125C3.75 10.4013 5.84867 12.5 8.4375 12.5Z" stroke="#626669" stroke-width="1.3" stroke-miterlimit="10"/>
                                    <path d="M1.73438 15.6249C2.55567 14.6461 3.58138 13.8589 4.73942 13.3189C5.89746 12.7789 7.15973 12.499 8.4375 12.499C9.71527 12.499 10.9775 12.7789 12.1356 13.3189C13.2936 13.8589 14.3193 14.6461 15.1406 15.6249" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M17.1875 11.875C17.8779 11.875 18.4375 11.3154 18.4375 10.625C18.4375 9.93464 17.8779 9.375 17.1875 9.375C16.4971 9.375 15.9375 9.93464 15.9375 10.625C15.9375 11.3154 16.4971 11.875 17.1875 11.875Z" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M17.1875 9.375V8.4375" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.1016 10L15.2969 9.53125" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.1016 11.25L15.2969 11.7188" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M17.1875 11.875V12.8125" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.2734 11.25L19.0781 11.7188" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.2734 10L19.0781 9.53125" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="box h-auto lg:pb-[0] pb-[16px] bg-white rounded-[8px]">
                        <div class="info info2 pl-[31px] pt-[21px] h-full flex">
                            <div class="text grow relative">
                                <div>
                                    <p class="font-medium text-black text-[16px]">Payrolls</p>
                                    <p class="font-[450] text-[#000000AD] text-[14px]">Total number of Payrolls</p>
                                </div>
                                <div class="lg:mb-[0] mb-[24px] mt-[10px]">
                                    <p class="font-bold text-[24px] leading-[36px] text-[#222222DE]">{{ dashboard.copied.payrolls }}</p>
                                </div>
                                <div>
                                    <p class="font-medium text-black text-[16px]">Income</p>
                                    <!-- <p class="font-[450] text-[#000000AD] text-[14px]">Total number of Payrolls</p> -->
                                </div>
                                <div class="lg:mb-[0] mb-[24px] mt-[10px]">
                                    <p class="font-bold text-[24px] leading-[36px] text-[#222222DE]">{{ dashboard.copied.income }}</p>
                                </div>
                                <!-- <router-link to="/manage-user" class="lg:text-[14px] table text-[14px] lg:absolute bottom-[16px] font-medium rounded-[200px] text-[#0960CD] bg-[#F0F2F2] h-[41px] leading-[41px] items-center">
                                    <span class="px-[17px] pr-0 float-left">Manage Admin Users</span>
                                    <svg class="ml-[10px] translate-y-[10.5px] float-right mr-[15px]" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.125 10.5H16.875" stroke="#0960CD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M11.25 4.875L16.875 10.5L11.25 16.125" stroke="#0960CD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </router-link> -->
                            </div>
                            <div class="icon w-[48px] h-[48px] mr-[24px] flex items-center justify-center rounded-full bg-[#F0F2F2]">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 3.125V16.875" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8.125 16.875H11.875" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M4.375 6.875L15.625 4.375" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1.875 13.125C1.875 14.5078 3.4375 15 4.375 15C5.3125 15 6.875 14.5078 6.875 13.125L4.375 6.875L1.875 13.125Z" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13.125 10.625C13.125 12.0078 14.6875 12.5 15.625 12.5C16.5625 12.5 18.125 12.0078 18.125 10.625L15.625 4.375L13.125 10.625Z" stroke="#626669" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lg:px-6 px-4 mt-3 lg:mt-6">
                <div class="lg:flex divide-x divide-grey bg-white border-rgba rounded-lg">
                    <div class="grow">
                        <Bar :height="400" :data="barChart.chartData" :options="barChart.chartOptions" />
                    </div>
                    <div class="py-5 lg:w-[28%]">
                        <div class="p-3">
                            <h3 class="text-2xl text-black-rgba font-medium">₦ {{ currencyFormat(chartAmount) }}</h3>
                            <p class="text-sm text-black-rgba-100 font-semimedium">
                            Total payments made this year
                            </p>
                        </div>

                        <div class="">
                            <div
                                class="flex items-center justify-between p-3 border-t border-grey"
                            >
                                <div class="flex items-center space-x-2+1">
                                    <span class="bg-[#306651] p-1.5 rounded-full"></span>
                                    <span class="text-sm text-black-rgba font-normal ml-2">Salary</span>
                                </div>
                                <div class="space-x-7">
                                    <span class="text-sm text-black-rgba font-semibold">₦ {{ currencyFormat(chartAmount) }}</span>
                                    <!-- <span class="text-sm text-black-rgba font-semibold">15%</span> -->
                                </div>
                            </div>
                            <!--  -->
                            <!-- <div class="flex items-center justify-between py-3-1">
                            <div class="flex items-center space-x-2+1">
                                <span class="bg-blue p-1.5 rounded-full"></span>
                                <span class="text-sm text-black-rgba font-normal">Taxes</span>
                            </div>
                            <div class="space-x-7-1">
                                <span class="text-sm text-black-rgba font-semibold"
                                >₦300,600</span
                                >
                                <span class="text-sm text-black-rgba font-semibold">85%</span>
                            </div>
                            </div> -->
                            <!--  -->
                            <!-- <div class="flex items-center justify-between py-3-1">
                            <div class="flex items-center space-x-2+1">
                                <span class="bg-orange p-1.5 rounded-full"></span>
                                <span class="text-sm text-black-rgba font-normal">Pension</span>
                            </div>
                            <div class="space-x-7-1">
                                <span class="text-sm text-black-rgba font-semibold"
                                >₦290,500</span
                                >
                                <span class="text-sm text-black-rgba font-semibold">48%</span>
                            </div>
                            </div> -->
                            <!--  -->
                            <!-- <div class="flex items-center justify-between py-3-1">
                            <div class="flex items-center space-x-2+1">
                                <span class="bg-red-100 p-1.5 rounded-full"></span>
                                <span class="text-sm text-black-rgba font-normal">Health</span>
                            </div>
                            <div class="space-x-7-1">
                                <span class="text-sm text-black-rgba font-semibold"
                                >₦2.80M</span
                                >
                                <span class="text-sm text-black-rgba font-semibold">36%</span>
                            </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                </div>
        </div>
    </div>    
</template>

<script>
    import SideNav from "@/components/shared/partials/SideNav.vue"
    import HeroCard from "@/components/shared/partials/HeroCard.vue"
    import MobileNav from "@/components/shared/partials/MobileNav.vue"
    // import BarChart from '@/components/BarChart/MainChart.vue'
    // import { Bar } from 'vue-chartjs'
    // import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
    
    // ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

    export default {
        name: 'HomeView',
        components: {
            // Bar,
            // BarChart,
            SideNav,
            HeroCard,
            MobileNav
        },
    }
</script>

<script setup>
    import { Bar } from 'vue-chartjs'
    import { useDashboard } from "@/store/dashboard"
    import {useProfile} from '@/store/profile'
    import { useBarChart } from '@/store/barChart'
    import { currencyFormat } from '@/utils/helper'
    import { onMounted, ref } from 'vue'

    const dashboard = useDashboard()
    const profile = useProfile()
    const barChart = useBarChart();
    const chartAmount = ref(0);

    onMounted(async () => {
        profile.setProfile();
        await dashboard.fetchDashboard()
        barChart.updateChart(dashboard.copied.payments)

        chartAmount.value = Object.values(dashboard.copied.payments).reduce((acc, curr) => acc + curr, 0)
    })

    const timeOfDay = () => {
        const hrs = new Date().getHours();
        if (hrs < 12)
            return 'Good Morning';
        else if (hrs >= 12 && hrs < 17)
            return 'Good Afternoon';
        else if (hrs >= 17 && hrs <= 24)
            return 'Good Evening';
    }
</script>

<style lang="scss" scoped>
    .info .text, 
    .info a{
        font-size: 14px;
    }
    #my-chart-id{
        height: 225px !important;
    }
    .bar-chart, .briefing .wrap{
        margin: 0 auto;
    }

    html, body{
        background: #F0F2F2;
    }

    .notification-dropdown a:first-of-type{
        padding-top: 0;
    }

    .notification-dropdown a:last-of-type{
        border-bottom: 0;
    }

    @media (max-width: 1200px){
        .briefing{
            height:auto;
            .wrap{
                height: auto;
                flex-flow: column;
                grid-template-columns: 1fr;
                a{
                    position: static;
                }
                .box{
                    width: 100%;
                    padding-bottom: 16px;
                    .text div:nth-of-type(2){
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
</style>