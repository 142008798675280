<template>
    <div class="hero-card h-[288px] pl-[24px] pr-[18px]" :style="'background-color: '+color">
        <div class="hero-card_content relative w-100% max-w-[1138px] h-full">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeroCard',
    props: {
        name: String,
        color: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .hero-card_content{
        margin: 0 auto;
    }
</style>
  